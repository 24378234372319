.gui-component {
    position: relative;
    display: inline-block; // .text-input {
    //     display: inline-block;
    //     background-color: transparent;
    //     border: none;
    //     border-bottom: 2px solid #404040;
    //     text-align: center; // font-size: 18px;
    //     // line-height: 50px;
    //     // height: 50px;
    //     color: #fff;
    //     outline: 0;
    //     &:focus,
    //     &:active {
    //         outline: none;
    //         box-shadow: 0;
    //     }
    //     &.input-error {
    //         border-color: #f53e3e;
    //         color: #f53e3e;
    //     }
    // }
    textarea,
    input {
        font-size: auto;
        padding: auto;
        display: block;
       
        border: auto;
        background-color: transparent;
        color: $gray;
    }
    textarea:focus,
    input:focus {
        outline: none;
    }
    /* LABEL ======================================= */
    label {
        color: $gray; 
        position: absolute;
        pointer-events: none;
        left: 18px;
        padding-left: 5px;
        padding-right: 5px;
        top: 10px;
        transition: 0.2s ease all;
        -moz-transition: 0.2s ease all;
        -webkit-transition: 0.2s ease all;
    }
    /* active state */
    textarea:focus~label,
    input:focus~label {
        top: -8px;
        font-size: 12.6px;
        color: $black;
        background-color: $white;   
    }

    textarea:valid~label,
    input:valid~label {
        top: -8px;
        font-size: 12.6px;
        color: $gray;
        background-color: $white;    
    }

    /* BOTTOM BARS ================================= */
    .bar {
        position: relative;
        display: block;
        width: auto;
    }
    .bar:before,
    .bar:after {
        content: '';
        height: 2px;
        width: 0;
        bottom: 1px;
        position: absolute;
        background: $green;
        transition: 0.2s ease all;
        -moz-transition: 0.2s ease all;
        -webkit-transition: 0.2s ease all;
    }
    .bar:before {
        left: 50%;
    }
    .bar:after {
        right: 50%;
    }
    /* active state */
    textarea:focus~.bar:before,
    textarea:focus~.bar:after,
    input:focus~.bar:before,
    input:focus~.bar:after {
        width: 50%;
    }
    /* HIGHLIGHTER ================================== */
    .highlight {
        position: absolute;
        height: 60%;
        width: auto;
        top: 25%;
        left: 0;
        pointer-events: none;
        opacity: 0.5;
    }
    /* active state */
    textarea:focus~.highlight,
    input:focus~.highlight {
        -webkit-animation: inputHighlighter 0.3s ease;
        -moz-animation: inputHighlighter 0.3s ease;
        animation: inputHighlighter 0.3s ease;
    }
    /* ANIMATIONS ================ */
    @-webkit-keyframes inputHighlighter {
        from {
            background: #5264AE;
        }
        to {
            width: 0;
            background: transparent;
        }
    }
    @-moz-keyframes inputHighlighter {
        from {
            background: #5264AE;
        }
        to {
            width: 0;
            background: transparent;
        }
    }
    @keyframes inputHighlighter {
        from {
            background: #5264AE;
        }
        to {
            width: 0;
            background: transparent;
        }
    }
}