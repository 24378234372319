.visual {
    position: relative;
    overflow: hidden;
    .mask {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 0;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        &.in {
            position: absolute;
            left: 0;
            right: 0;
            top: 50%;
            bottom: unset;
            z-index: 0;
            background-size: auto;
            background-position: center;
            background-repeat: no-repeat;
            left: -100%;
            right: -100%;
            top: -100%;
            bottom: -100%;
        }
    }
    .content,
    .content .box {
        position: relative;
        height: 100%;
        color: #fff;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 0 15px;
        h1 {
            //background-color: $white;
            //padding: 10px 25px;
            color: $white;
            text-shadow: 0px 5px 50px $black;
        }
    }
    .content.box {
        min-height: 300px;
        img {
            @extend .img-fluid;
        }
    }
    .content {
        justify-self: safe center;
        justify-self: center;
        min-height: 100%;
        padding-top: 90px;
        padding-bottom: 90px;
        margin: auto;
    }
}

.available {
    background-color: $gray-lighter;
    margin-top: 0px;
    padding-top: 50px;
    padding-top: calc(65px - 1rem);
    padding-bottom: 50px;
    p {
        font-size: 1.3rem;
    }
}

// Set souvisejících služeb

.ws-next-services-stripe {
    background-color: $white;
    text-align: left;
    padding-top: 80px;
    padding-bottom: 80px;
    color: $black;
    .ws-item {
        
    .wrapper-arrow {
        float: right;
            .arrow {
                margin-left: 0px;
                margin-top: -3px;
                fill: $black;
                
                opacity: .7;
                &:hover,
                &:active,
                &:focus {
                    fill: #000; 
                    opacity: 1.0;
                }
                &.small
                {
                width: 100%;
                height: 100%;
                }
        }  
    }
}
   
}

// Položka katalogu
.ws-item-product {
    background-color: $gray-lighter;
    .wrapper-arrow {
       
    }
    .ws-badge-in-product {
        z-index: 10;
        position: absolute !important;
        top: 25px !important;
        left: 13px !important;
        text-align: left !important;
        .badge {
        margin-bottom: 3px;
    }
    }
    
}




.ws-products,
.ws-subnav-stripe {
    
    padding-left: 25px;
    padding-right: 25px;
    // Extra large devices (large desktops, 1325px and up)
@media (min-width: 1325px) {
    padding-right: 25px;   
    padding-left: 25px;
    
    
}
}


.ws-product-detail {
    h1 {
        font-size: $h1-font-size-product;
    }

    .ws-mareketing-lead {
        margin-bottom: 35px;
    }

    .ws-prices {
        margin-bottom: 20px;
        margin-top: 20px;
    }

    .ws-back-up {
        svg {
            margin-top: -4px;
            margin-right: 4px;
        }
    }
    .ws-specification {
    background-color: $gray-lighter;
    padding: 20px 25px 15px 25px;
    margin-top: 40px;
    }
    .ws-badge-in-product {
        z-index: 10;
        position: absolute !important;
        top: 25px !important;
        left: 0px !important;
        text-align: left !important;
        .badge {
        margin-bottom: 3px;
    }
    }
    
} 


// HP video panel

.video-panel{
	text-align: center;
    background: #fff;
    margin-bottom: -25px;


 
    a.ws-video-btn {
        position: relative;
        margin-top: -25px;
        z-index: 10;
        display: inline-block;
  
        width: 161px; height: 161px;
        background: url("../Content/images/play.png") no-repeat left top;
    
        &:hover,
        &:active,
        &:focus {
            background-position: 0 -161px; 
            cursor: pointer;
        }

    }
    .item-cross {
       
        margin-top: 47px;
        margin-bottom: 40px;
       
    }
    h3 {
        color: $primary;
    @include media-breakpoint-down(xs){
        
            font-size: 1.3rem;
        
    
    }
    }
}




// tables in order
.ws-thumb-cart {
    width: 100px;
}

.ws-grid-table-row {
    border-top: 1px solid $border-color;
    border-left: 1px solid $border-color;
    border-right: 1px solid $border-color;
    padding: 15px;
    .ws-cart-img {
        background-color: $gray-lighter;
        text-align: center;
        padding-top: 20px;
        padding-bottom: 20px;
    }
    .ws-delete-item {
        background-color: $black;
        opacity: .25;
        margin-left: 10px;
        position: relative;
      
        display: inline-block;
        width: 26px;
        height: 26px;
        border-radius: 50% !important;
        &:hover,
        &:focus {
            opacity: .5;
            cursor: pointer;
        }
        svg {
            margin-top: -6px;
            margin-right: 7px;
            fill: $white;

            @include media-breakpoint-down(sm) {
                margin-right: 0px;
              }
           
        }
    }
}

.ws-hover-row {
    .ws-grid-table-row {

    &:hover,
    &:focus {
        background-color: $gray-lighter;
        
    }
}
}

.ws-grid-table-row {
    &.box-info { 
        .col {
        ul {
            padding-left: 20px;
            li {
                line-height: 1.7rem !important;
            }
            @include media-breakpoint-down(sm){
        
            text-align: left;
            
        
        }
        }
    }
    }
}


.ws-grid-table-row:last-child {
    border-bottom: 1px solid $border-color !important;
  }

.ws-summary {
    padding-top: 20px;
    padding-top: 20px;
}



// dlaždice seznamu
.ws-items-stripe {

    .ws-item-category {
        margin-bottom: 1.7rem;
        background-color: $black;
        position: relative;
        overflow: hidden;

        .text-content {
            margin-top: 90%;
            padding: 25px 25px 12px 25px;
            color: $white !important;
            position: relative;
            transition: background-color 300ms ease;
            h1, h2, h3, h4, h5 {
                color: $white !important;
            }
        }

        .mask {
            position: absolute;
            left: 50%;
            top: 50%;
            width: 100%;
            height: 100%;

            img {
                min-height: 100%;
                min-width: 100%;
                transform: translate(-50%, -50%);
                transition: all 300ms ease;
            }
        }
        .text-content.with-background {
            background-color: $primary !important;
        }
        &:hover {
            cursor: pointer;
            .mask {
                img {
                    transform: translate(-50%, -50%) scale(1.05);
                }
            }
            .text-content.with-background {
                background-color: $black !important;
            }
        }

    }

    .ws-item-product {
        margin-bottom: 1.7rem; 
        background-color: $gray-lighter;
        border: 1px solid $gray-lighter;
        padding-top: 30px;
        min-height: 420px;
        .product-info {
            padding: 20px 10px 15px 10px;
        
        
        h2, .h5 {
            text-transform: uppercase;
        }
        }
        img {
             max-height: 190px;      
        }

        &:hover {
            cursor: pointer;
            border: 1px solid $primary;
            a {
                color: $black;
            }
               
        }

    }

}

.ws-subnav-stripe {
    background-color: $white; 
    .row {
        
       
    }
}

.ws-client-stripe {
    background-color: $gray-lighter;
    .row {
        padding-bottom: 80px;
            padding-top: 80px;
            margin-top: 0;
            margin-bottom: 0;
        svg {
            fill: $black;
        }
    }
}

// Footer
footer {
    background-color: $gray-dark;
    color: $white;


    

    .ws-cta-stripe {
        background-color: inherit;
        h2 {
            color: $white;
            line-height: 72px;
            padding-bottom: 65px;
            padding-top: 65px;
            margin-top: 0;
            margin-bottom: 0;
            .btn {
                margin-left: 4px;
                margin-right: 4px;
            }
        }
        .container {
            border-top: none;
        }
    }

    .ws-nav-stripe {
        background-color: inherit;
        padding-bottom: 50px;
        padding-top: 60px;
        .row {
            margin-top: 0;
            margin-bottom: 0;
            .nav {
                border-right: 0px solid $gray-dark;
                .ws-main-heading {
                    h4 a {
                        opacity: 1.0;
                        color: $white !important;
                        &:hover,
                        &:active,
                        &:focus {
                            text-decoration: none;
                            color: darken($green, 0%) !important;
                        }
                    }
                }
            }
            a.nav-link {
                opacity: .5;
                color: $white;
                line-height: auto;
                padding-top: 0.25rem;
                padding-bottom: 0.25rem;
                &:hover,
                &:active,
                &:focus {
                    text-decoration: none;
                    opacity: 1.0;
                    color: $green !important;
                }
            }
        }
        .container {
            border-top: 0px solid $gray-darker;
        }
    }

    .ws-certification-stripe {
        background-color: inherit;
        color: inherit;
        .row {
            margin-top: 0;
            margin-bottom: 0;
            padding-top: 40px;
            padding-bottom: 40px;
            h5 {
                color: $white;
            }
            p {
                margin-bottom: 0;
                opacity: .5;
                color: $white;
            }
            svg.ws-logo{ 
                opacity: .5;
                fill: $white;
                &.ugd {
                    margin-top: 18px;
                    margin-bottom: 5px;
                }
                &.g-suite {
                    margin-top: 22px;
                    margin-bottom: 5px;
                }
                &.google-partner {
                    margin-top: 0px;
                    margin-bottom: 5px;
                }
                }
            .ws-item {
                border-right: 1px solid $gray-dark;   
            }
        }
        .container {
            border-top: 0px solid $gray-dark;   
        }
    }

    .ws-contact-stripe {
        background-color: inherit;
        color: $green;
     
        
        .row {
            margin-top: 0;
            margin-bottom: 0;
            padding-top: 50px;
            padding-bottom: 50px;
            p {
                margin-bottom: 0;
                span {
                    opacity: 1.0;
                }
            }
            .ws-item {
                border-right: 0px solid $gray-dark;    
            }
        }
        a { 
            svg.logo, svg.ws-sn { 
            opacity: 1.0;
            fill: $white;
                &:hover,
                &:active,
                &:focus {
                    text-decoration: none;
                    fill: darken($green, 0%);
                }
            }
            svg.ws-sn {
                margin-right: 8px;
                margin-bottom: 3px;
            }
        }
        a {    
            opacity: 1.0;
            color: $white;
                &:hover,
                &:active,
                &:focus {
                    text-decoration: none;
                    color: darken($green, 0%);
                }
        }
        .container {
            border-top: 1px solid $gray-800;   
        }
        border-top: 0px solid $gray;
        
    }

    .ws-bottom-stripe {
        background-color: inherit;
        .row {
            margin-top: 0;
            margin-bottom: 0;
            padding-top: 40px;
            padding-bottom: 40px;  
            opacity: .5;
            color: $white;          
            a { 
                svg.ws-logo,
                svg.ws-sn { 
                    opacity: 0.75;
                    fill: $white;
                    &:hover,
                    &:active,
                    &:focus {
                        opacity: 1.0;
                        fill: $white;
                    }
                }
                svg.ws-sn { 
                   
                      margin-left: 8px;
                }
                svg.ws-logo { 
                   
                   margin-top: 20px;
              }
             

            }

            svg.ws-logo,
            svg.ws-sn { 
                opacity: 0.75;
                fill: $white;
            }
        }
        .container {
            border-top: 1px solid $gray-800;
        }
        border-top: 0px solid $gray;
    }

}  


.available{
    background: $gray-lighter;
    padding-top: 45px;
    padding-bottom: 15px; 
    .media {
        .media-body {
            margin-left: 5px;
        h4 {
            color: $primary;
            text-transform: uppercase;
            font-size: 1rem;
            margin-bottom: 3px;
        }
        p {
            font-size: 0.85rem;
        }
    }
    }
}