.ws-filtr-wrapper  {
    border: 1px solid $gray-light;
    padding: 25px;
}

.rheostat-progress {
    background-color: $green !important;
}

.rheostat-handle {
    background-color: $primary !important;
    border-color: $primary !important;

}  

.rheostat-background {
    border: 1px solid $gray-light;

}

p.wLabel {
font-size: $small-font-size * 0.9;
margin-top: 35px;
}