$font-size: 18px;

//
// Color system
//

$blue:           #1976d2;
$blue-dark:      #443d91;
$blue-darker:    #3d3681;
$red:            #d32f2f;
$green:          #89bf42;
$green-dark:     #276159;
$green-darker:   #3C6018;
$yellow:         #ffc22b;
$black:          #1c1c1d;
$white:          #ffffff;
$gray:           #b1b1b1;
$gray-dark:      #212121;
$gray-darker:    #666;
$gray-lighter:   #f7f7f7;
$gray-light:     #e5e5e5;
$biege:          #f1eadc;






$primary:       $green-dark;
$secondary:     $gray-600 !default;
$success:       $green-darker;
$info:          $blue;
$warning:       $yellow !default;
$danger:        $red;
$light:         $gray-100 !default;
$dark:          $gray-800 !default;


$theme-colors: ();
// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors: map-merge(
  (
    "primary":    $primary,
    "secondary":  $secondary,
    "success":    $success,
    "info":       $info,
    "warning":    $warning,
    "danger":     $danger,
    "light":      $light,
    "dark":       $dark
  ),
  $theme-colors
);
// Body
//
// Settings for the `<body>` element.

$body-color:                $gray-dark;

// Links
//
// Style anchor elements.

$link-color:                inherit;
$link-decoration:           none;
$link-hover-color:          $green;
$link-hover-decoration:     none;


// Components
//
// Define common padding and border radius sizes and more.

$border-radius:               0rem;
$border-radius-lg:            0rem;
$border-radius-sm:            0rem;

$input-border-radius:                   $border-radius;
$input-border-radius-lg:                $border-radius-lg;
$input-border-radius-sm:                $border-radius-sm;

$alert-border-radius:               $border-radius;

$component-active-color:      $white;
$component-active-bg:         theme-color("primary");


// Fonts
//
// Font, line-height, and color for body text, headings, and more.


// Body
//
// Settings for the `<body>` element.

$body-bg:                   $white;
$body-color:                $black;

// stylelint-disable value-keyword-case
$font-family-sans-serif:      "Open Sans", Arial, sans-serif;
$font-family-base:            $font-family-sans-serif;
// stylelint-enable value-keyword-case

$font-size-base:              1.125rem; // Assumes the browser default, typically `16.64px`

$font-weight-light:           normal;
$font-weight-normal:          normal;
$font-weight-bold:            normal;

$line-height-base:            1.5;

$h1-font-size:                $font-size-base * 2.50;
$h1-font-size-product:        $font-size-base * 1.75;
$h2-font-size:                2.125rem; //$font-size-base * 1.60;
$h3-font-size:                1.625rem; //$font-size-base * 1.38;
$h4-font-size:                $font-size-base * 1.25;
$h5-font-size:                $font-size-base * 1.00;

$headings-font-family:        "Georgia Regular", Arial, sans-serif;
$headings-font-family-alternative:  "Proxima N W02 Bold","Georgia Regular", Arial, sans-serif;
$headings-color:              $black;


$lead-font-size:              ($font-size-base * 1.30);

$small-font-size:             87.5%;

// Navbar
$navbar-padding-y:                  0;
$navbar-nav-link-padding-x:         1.1rem;
$navbar-padding-x:                  1.1rem;

// Compute the navbar-brand padding-y so the navbar-brand will have the same height as navbar-text and nav-link

$navbar-light-color:                $gray-darker;
$navbar-light-hover-color:          $gray-darker;
$navbar-light-active-color:         $gray-darker;
$navbar-light-toggler-border-color: none;

// Forms
$input-focus-border-color:              $black;
$input-focus-box-shadow:                none;

$input-color:                           $gray;
$input-focus-color:                     $black;

// Custom
$custom-select-border-color:        $input-border-color;
$custom-select-border-radius:       $border-radius;
$custom-select-box-shadow:          none;

$custom-select-focus-border-color:  $input-focus-border-color;
$custom-select-focus-box-shadow:    none;

$lightgal-spacing:	15px;

// Badges

$badge-font-size:                   75%;
$badge-font-weight:                 $font-weight-normal;
$badge-padding-y:                   .45em;
$badge-padding-x:                   .55em;
$badge-border-radius:               $border-radius;

// Navs

$nav-link-padding-y:                .5rem !default;
$nav-link-padding-x:                1rem !default;
$nav-link-disabled-color:           $gray-600 !default;

$nav-tabs-border-color:             $gray-300 !default;
$nav-tabs-border-width:             $border-width !default;
$nav-tabs-border-radius:            $border-radius !default;
$nav-tabs-link-hover-border-color:  $gray-200 $gray-200 $nav-tabs-border-color !default;
$nav-tabs-link-active-color:        $gray-700 !default;
$nav-tabs-link-active-bg:           $body-bg !default;
$nav-tabs-link-active-border-color: $gray-300 $gray-300 $nav-tabs-link-active-bg !default;

$nav-pills-border-radius:           $border-radius;
$nav-pills-link-active-color:       $component-active-color;
$nav-pills-link-active-bg:          $component-active-bg;

// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.

$table-cell-padding:          1.25rem;

// Custom forms

$custom-control-indicator-bg:           $input-bg;

$custom-control-indicator-box-shadow:   $input-box-shadow;
$custom-control-indicator-border-color: $gray-500;
$custom-control-indicator-border-width: $input-border-width;

$custom-control-indicator-disabled-bg:          $input-disabled-bg;
$custom-control-label-disabled-color:           $gray-600;

$custom-control-indicator-checked-color:        $component-active-color;
$custom-control-indicator-checked-bg:           $black;
$custom-control-indicator-checked-disabled-bg:  rgba(theme-color("primary"), .5);
$custom-control-indicator-checked-box-shadow:   none;
$custom-control-indicator-checked-border-color: $custom-control-indicator-checked-bg;

$custom-control-indicator-focus-box-shadow:     $input-focus-box-shadow;
$custom-control-indicator-focus-border-color:   $input-focus-border-color;

$custom-control-indicator-active-color:         $black;
$custom-control-indicator-active-bg:            lighten($black, 35%);
$custom-control-indicator-active-box-shadow:    none;
$custom-control-indicator-active-border-color:  $custom-control-indicator-active-bg;
