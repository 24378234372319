@font-face{
    font-family: 'lg';
    src: url("../Content/fonts/lg.eot?n1z373");
    src: url("../Content/fonts/lg.eot?#iefixn1z373") format("embedded-opentype"), url("../Content/fonts/lg.woff?n1z373") format("woff"), url("../Content/fonts/lg.ttf?n1z373") format("truetype"), url("../Content/fonts/lg.svg?n1z373#lg") format("svg");
    font-weight: normal;
    font-style: normal;
}
@font-face {
font-family: 'Georgia Regular';
font-style: normal;
font-weight: normal;
src: local('Georgia Regular'), url('../Content/fonts/georgia.woff') format('woff');
}


@font-face {
font-family: 'Georgia Italic';
font-style: normal;
font-weight: normal;
src: local('Georgia Italic'), url('../Content/fonts/georgiai.woff') format('woff');
}


@font-face {
font-family: 'Georgia Bold';
font-style: normal;
font-weight: normal;
src: local('Georgia Bold'), url('../Content/fonts/georgiab.woff') format('woff');
}


@font-face {
font-family: 'Georgia Bold Italic';
font-style: normal;
font-weight: normal;
src: local('Georgia Bold Italic'), url('../Content/fonts/georgiaz.woff') format('woff');
}

// PROXIMA NOVA (font-style)
@font-face{
font-family:"Proxima N W02 Reg";
src:url("../Content/fonts/proxima-nova/981c9065-bc6e-45d0-a53d-3586c9477bc1.eot?#iefix");
src:url("../Content/fonts/proxima-nova/981c9065-bc6e-45d0-a53d-3586c9477bc1.eot?#iefix") format("eot"),
    url("../Content/fonts/proxima-nova/2f984d7d-ee4e-4936-8288-fe7f8637788f.woff2") format("woff2"),
    url("../Content/fonts/proxima-nova/74df122e-b294-4b16-a1fd-308654b1c664.woff") format("woff"),
    url("../Content/fonts/proxima-nova/7389e916-8cc8-4f82-9de6-4174b4ea5dd5.ttf") format("truetype"),
    url("../Content/fonts/proxima-nova/67059dfa-2992-46b3-a928-def96c0f85fe.svg#67059dfa-2992-46b3-a928-def96c0f85fe") format("svg");
}

@font-face{
font-family:"Proxima N W02 Bold";
src:url("../Content/fonts/proxima-nova/b9234758-e038-4622-b531-1e89eef11dc8.eot?#iefix");
src:url("../Content/fonts/proxima-nova/b9234758-e038-4622-b531-1e89eef11dc8.eot?#iefix") format("eot"),
    url("../Content/fonts/proxima-nova/301b29be-a6b5-467a-ba10-926b40436a87.woff2") format("woff2"),
    url("../Content/fonts/proxima-nova/a01242ac-07ee-44eb-97b4-3f6df729e51a.woff") format("woff"),
    url("../Content/fonts/proxima-nova/713e8042-cdd9-4f87-b79b-59bb8e43daca.ttf") format("truetype"),
    url("../Content/fonts/proxima-nova/b39f961b-4c68-4114-8a71-76bfe004cf14.svg#b39f961b-4c68-4114-8a71-76bfe004cf14") format("svg");
}

body{
    font-family:"Proxima N W02 Reg" !important;
}
:focus{
	outline: none;
}
.btn-success{
    background: $green-darker;
    border: thin solid $white;
}
.btn-success:hover,
.btn-success.active{

    background: $green-darker;
    border: thin solid $white;
}
h1,h2{
    font-family: "Georgia Bold",Times,Times New Roman,serif;
}
h2,h3,h4,h5,h6 {
    text-transform: none;
}

nav.navbar{
    padding-top: 50px !important;
    padding-bottom: 0;
    background: url(../Content/images/header.jpg) no-repeat top $green-darker;
    background-size: cover;
    line-height: inherit;
    color: $gray;
    display: flex;
    justify-content: center;
    > div{
        text-align: center;
        width: 100%;
        flex: 0 0 100%;
        a.navbar-brand{
            padding-right: 0;
        }
        h1{
            display: block;
            color: $white;
            letter-spacing: normal;
            // font-weight: bold;
            text-transform: uppercase;
            font-size: 3.3rem;
        }
        .subtitle{
            color: $white;
            font-family: "Georgia Regular",Times,Times New Roman,serif;
            text-transform: none;
            margin-bottom: 40px;
            margin-top: -15px;
            font-size: 1.45em;
        }
        p.header-alert{
            color: $white;
            font-size: $small-font-size;
            padding-top:60px;
        }
    }
    .search_results{
        background: $white;
        padding: 10px 15px;
        position: absolute;
        width: calc(100% - 30px);
        border-top: none;
        margin-top: -1px;
        text-align: left;
        color: $black;
        z-index: 9999;
        box-shadow: 1px 2px 3px 0px #eee;
        ul{
            list-style: none;
            padding-left: 5px;
            max-height: 70vh;
            scroll-behavior: auto;
            overflow-y: auto;
        }
        a{
            display: block;
            &:hover {
                color: $black;
            }
        }
    }
}

.infolist{
    background-image: -webkit-linear-gradient(left,#93bf1b 0,#f1f89e 100%);
    background-image: -o-linear-gradient(left,#93bf1b 0,#f1f89e 100%);
    background-image: linear-gradient(to right,#93bf1b 0,#f1f89e 100%);
    padding-top: 50px;
    padding-bottom: 40px;
}
.result-container{
    padding-top: 30px;
    padding-bottom: 30px;
    margin-bottom: 30px;
    .loader{
        svg{
            stroke: $green-darker
        }
    }
    .result-inner{
        h2{
            // font-weight: bold;
            color: $green-darker;
        }
        p.main-info{
            font-size: .8em;
        }
        .target-info{
                font-size: 1.3em;
                font-weight: bold;
                color: $green-darker;
        }
        .target-price{
                font-size: 1.3em;
                font-weight: bold;
                color: $red;
        }
        .location{
            color: $green-darker;
            line-height: 25px;
        }
        .alert-block{
            padding: 15px;
            margin: 15px;
            border: thin solid $gray-darker;
            p{
                color: $gray-darker;
            }
        }
    }
}

.page-sell h2 {
    // font-weight: bold;
    color: $green-darker;
}

.contact-form{
    h4{
        font-family:"Proxima N W02 Bold";
    }
    p{
        font-family:"Proxima N W02 Reg";
    }
    .alert-block{
        padding: 15px;
        margin: 5px;
        border: thin solid #666;
    }
    .contact-text{
        padding: 20px;
        align-self: center;
    }
}
.sellout-block{
    .contact{
        font-size: 1.2em;
        font-weight: bold;
    }
}
.customer-reinsurance{
    padding-top: 30px;
    padding-bottom: 30px;
    margin-top:30px;
    background-image: -webkit-linear-gradient(left,#93bf1b 0,#f1f89e 100%);
    background-image: -o-linear-gradient(left,#93bf1b 0,#f1f89e 100%);
    background-image: linear-gradient(to right,#93bf1b 0,#f1f89e 100%);
    text-align: center;
    h4{
        font-weight: bold;
        min-height: 50px;
        font-size: .9em;
    }
    p{
        font-size: .9em;
    }
}
.footer-contact{
    padding-top: 50px;
    padding-bottom: 50px;
    background: $gray-light;
    color: $gray-darker;
    h4{
        color: $gray-darker;
        font-weight: bold;
    }
    a{
        color: $green-darker;
        border-bottom: thin solid $gray-darker;
    }
}
footer{
    padding-top: 15px;
    padding-bottom: 15px;
    background: $white;
    color: $gray-darker;
}


.signature {
    width: 173px;
    height: 15px;
    background-position: left top;
    background-repeat: no-repeat;
    background-size: 100%;
    text-indent: -9999px;
    background-image: url(../Content/images/author_wense-interactive-design_black.png);
    display: block;
    margin-top: 12px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    float: right;
    opacity: .5;
    transition: .1s;

    &:hover {
        opacity: .8;
    }    
}