::selection {
    background-color: $primary;
    color: $white;
  }

  .ws-extra-small {
    font-size: 75%;
} 

.badge {
    margin-left: 2px !important;
    margin-right: 2px !important;
}

p strong,
p b {
    font-family: "Open Sans", Arial, sans-serif;
    font-weight: 600;
}

h1 strong, h2 strong, h3 strong, h4 strong, h5 strong,
h1 b, h2 b, h3 b, h4 b, h5 b,
.h1 b, .h2 b, .h3 b, .h4 b, .h5 b,
.h1 strong, .h2 strong, .h3 strong, .h4 strong, .h5 strong
{
    font-family: $headings-font-family;
    font-weight: 600;
}

h1, .h1 {
    letter-spacing: 0rem;
    text-transform: uppercase;

}

h2, .h2 {
    letter-spacing: 0rem;
    text-transform: uppercase;
}

h3, .h3 {
    letter-spacing: 0rem;
    text-transform: uppercase;
    font-family: $headings-font-family-alternative;    
}

h4, .h4 {
    letter-spacing: 0rem;
    text-transform: none;
    font-family: $headings-font-family-alternative;    
}

h5, .h5 {
    letter-spacing: 0rem;
    text-transform: none;
    font-family: $headings-font-family-alternative;    
}

p.lead {
    letter-spacing: 0rem;
}

ul {
    padding-inline-start: 1.2rem;
}

.ws-other-description {
    .media {
        padding:30px;
        width: 100%;
        }
.media:nth-child(2n) {
    background-color: $gray-lighter;
    }
    .media-body {
            p {
                font-size: $small-font-size;
            }
        }  
    
}
.ws-brand-deco {
   position: relative;
}

.ws-primary {
    color: $primary;
}

.ws-green {
    color: $green;
}
.ws-bg-dark {
    background-color: $gray-lighter;
}
.ws-red {
    color: $red;
}

.ws-opacity-50 {
    opacity: 0.5;
}

.ws-bigger {
    font-size: 1.5rem;
}
.ws-brand-deco:after {
    content : "";
    display: block;
    position: absolute;
    background-position: center 65% ;
    background-repeat: no-repeat;
    background-image: url("http://azure.wense.cz/data/mail-signature/logo-wense-symbol.svg"); 
    width: 100%;
    height: 100%;
    opacity : .035;
    z-index: -1;
}

img.kulate {
    border-radius: 320px;
}

.mt-6 {
    margin-top: ($spacer * 4.00) !important;
  }
  

p.lead {
    color: $black;
}

.content {

    ol.wense-special-list {
        display: block !important;
        list-style: none !important;
        padding-left: 44px !important;
        li {
            position: relative;

            
            margin-bottom: 1.0rem;
            margin-top: 1.0rem;
            &:before {
                content: "";
                content: attr(data-number);
                position: absolute;
                left: -44px;
                top: 0px;
                display: block;
                width: 32px;
                height: 32px;
                background-color: $primary;
                opacity: 1.0;
                color: $white;
                text-align: center;
                line-height: 32px;
                font-size: $small-font-size;
                border-radius: 50%;
            }
        }
    }
    
    ul.wense-special-list { 
        display: block !important;
        list-style: none !important;
        padding-left: 38px !important;
        li {
            position: relative;

          
            margin-bottom: 1.00rem;
            margin-top: 1.00rem;
            &:before {
                content: "" !important;
                position: absolute !important;
                left: -40px !important;
                top: 3px !important;
                display: block !important;
                width: 24px;
                height: 24px;
                background-color: $primary;
                opacity: 1.0 !important;
                color: $white;
                text-align: center !important;

                border-radius: 50% !important;
            }
        }
    }

    // special styl listu pro detailu produktu
    div.wense-special-list { 
        font-size: $small-font-size;
        ul { 
            display: block !important;
            list-style: none !important;
            padding-left: 22px !important;
            li {
                position: relative;

            
                margin-bottom: 0.5rem;
                margin-top: 0.5rem;
                &:before {
                    content: "" !important;
                    position: absolute !important;
                    left: -22px !important;
                    top: 8px !important;
                    display: block !important;
                    width: 9px;
                    height: 9px;
                    background-color: $black;
                    opacity: 1.0 !important;
                    color: $white;
                    text-align: center !important;

                    border-radius: 50% !important;
                }
            }
        }
    }



p a {
    color: $black;
    font-family: $font-family-sans-serif;
    font-weight: 700;
    text-decoration: none;
            &:hover,
            &:active,
            &:focus {
                text-decoration: none;
                color: $green;
        }
}

}
.open-new {
    margin-left: 3px;
    fill: $gray-dark;
}

.main-content {
    padding-top: 60px;
}

.container-fluid.header {
    .content {
        padding-top: 50px;
        padding-bottom: 25px;
    }
}

.container-fluid.body {
    .content {
        padding-top: 10px;
        padding-bottom: 25px;
    }
}

.container-fluid.conversion-part {
    background-color: $gray-lighter;
    text-align: center;
    padding-top: 80px;
    padding-bottom: 80px;   
    .content {
        
    }
}



.container-fluid.visual-home { 
    position: relative;
    .mask {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        background-color: $green;
        z-index: -1;
    }
    .content {
        padding: 140px 30px 50px 30px; 
        min-height: calc(100vh - 145px);
    }
}



.btn {
    text-decoration: none;
    font-family: $font-family-sans-serif;
    svg {
        &.ico-left { 
        margin-left: 0px;
        margin-right: 10px;
        }
        &.ico-right {
            margin-left: 10px !important;
            margin-right: 0px !important;
        }
        &.ws-ico-plus,
        &.ws-ico-minus {
            margin-left: 0px !important;
            margin-right: 0px !important;
            }
    }
    @include button-size(0px, 26px, $font-size-base - 0.1rem, 50px, $border-radius);
    &.btn-primary {
        @include button-variant($primary, $primary);
        color: $white;
        svg {
            fill: $white;
            
        }
    }
    &.btn-outline-primary {
        @include button-outline-variant($primary);
        
        svg {
            fill: $primary; 
        }
        &:hover,
        &:active,
        &:focus {
            svg {
                fill: $white !important; 
            }
        }
    }
    &.btn-secondary {
        @include button-variant($black, $black);
        svg {
            fill: $white;
        }
    }
    &.btn-outline-secondary {
        @include button-outline-variant($black);
        svg {
            fill: $black;
        }
        &:hover,
        &:active,
        &:focus {
            svg {
                fill: $white !important; 
            }
        }
    }

    &.btn-outline-secondary-no-fill {
        @include button-outline-variant($gray);
        border-color: $gray-400;
        svg {
            fill: $gray;
        }
        &:hover,
        &:active,
        &:focus {
            background-color: $white !important;
            border-color: $black; 
            svg {
                fill: $black !important;  
            }
        }
    }
    
    &.btn-lg {
        @include button-size(0px, 30px, $font-size-base, 56px, $border-radius);
        svg {
            margin-left: -6px;
            margin-right: 10px;
        }
    }

    &.btn-xl {
        @include button-size(0px, 32px, $font-size-base * 1.12, 58px, $border-radius);
        svg {
            margin-left: -6px;
            margin-right: 10px;
        }
    }
    
    &.btn-sm {
        @include button-size(0px, 15px, rem(14px), 22px, $border-radius);
    }
    &:hover,
    &:active,
    &:focus {
        box-shadow: none !important;
    }
}

.form-control-lx {
    height: 58px !important;
    font-size: $font-size-base !important;
}

.form-control:focus {
    border: 1px solid #ced4da;
}



.modal-backdrop {
    background-color: #000;
    &.show {
        opacity: 1;
    }
}

.form-modal {
    .modal-content {
        background-color: transparent;
        color: #ffffff !important;
        border: 0;
        box-shadow: none;
        text-align: center;

        h1, h2, h3, p {
            color: #ffffff !important;
        }
    }
    .novalid {
        border-color: #ff0000;
    }
    .close-modal {
        font-size: 50px;
        color: #fff;
        right: 25px;
        top: 25px;
        padding: 0 20px;
        position: absolute;
        cursor: pointer;
        transition: opacity 300ms ease;
        &:hover {
            opacity: .85;
        }
    }
}