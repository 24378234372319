// Horni pruh (custom styles)
.ws-top-nav {
    width: 100%;
    position: relative;
    background-color: $green-dark;
    font-size: $small-font-size * 0.88;
    padding-left: 10px;

    .nav-link {
        text-decoration: none;
        opacity: .5;
        color: $white;
        fill: $white;
        
        svg {
            margin-right: 3px;
            margin-top: -3px;

        }
        &:hover,
        &:active,
        &:focus {
            text-decoration: none;
            color: $green;
            fill: $green;
            opacity: 1.0;
        }
    }
}

// Proces objednávky (custom styles)
.ws-order-nav {
    width: 100%;
    position: relative;
    background-color: $primary;
    font-size: 1.2rem;
    
    .nav-link {
        text-decoration: none;
        opacity: .5;
        padding-bottom: 16px;
        padding-top: 16px;
        color: $white;
        padding-left: 0;
        text-align: left;
        svg {
            
            fill: $white;
            opacity: .5;
            margin-right: 8px !important;
    
        }
        span.name {
            color: $white;
           
        }
        span.number {
            position: relative;
            display: inline-block;
            width: 28px;
            height: 28px;
            border-radius: 50% !important;
            padding-left: 8px;
            margin-right: 8px !important;;
            background-color: $white;
            opacity: 1.0;
            color: $primary;
        }
        &.active {
            color: $white;
            opacity: 1.0;
            cursor: default;
            span.number {
                background-color: $green;
                opacity: 1.0;
                color: $primary;
            }
            span.name {
                color: $white;
                opacity: 1.0;
            }
            &:hover,
            &:active,
            &:focus {
                color: $white;
                opacity: 1.0;
                span.number {
                    background-color: $green;
                    opacity: 1.0;
                    color: $primary;
                }
                svg {
            

             
                    fill: $white;
                opacity: .5 !important;
        
            }
            }
        }

        &:hover,
        &:active, 
        &:focus {
            text-decoration: none;
            color: $white;
            fill: $green;
            opacity: 1.0;
            cursor: pointer;
            span.number {
                background-color: $green;
                opacity: 1.0;
                color: $primary;
            }
            svg {
            

             
                fill: $white;
                opacity: .25 !important;
        
            }
        }
    }
}


// Hlavni navigace (custom styles)

nav.navbar {
    line-height: 74px;
    padding-left: 25px;
    padding-right: 25px;
    .navbar-brand {
        padding-right: 10px;
        svg.logo {
            fill: $black;
            &:hover,
            &:active,
            &:focus {
                
        }
        }
    }
    .nav-item {
        &.highlight a { 
            
            font-weight: 600; 
        }
    }
    .active {
        .nav-link {
            border-top-color: $green !important;
            color: $black !important;
            .ws-ico {
                fill: $black !important;
            }
        }
    }
    
    .nav-link {
        
        font-family: $headings-font-family;
        font-size: 1.1rem;
        text-decoration: none;
        border-top: 5px solid;
        border-top-color: transparent;
        opacity: 1.0;
        color: $black !important;
        @media (min-width: 1460px) { 
            padding-left: 1.5rem !important;
         }
        
        &:hover,
        &:active,
        &:focus {
            text-decoration: none;
            border-top-color: $green !important;
            color: $black !important;
            opacity: 1.0;
        }
    }
    .ws-navbar-eshop {
         .nav-link {
    
        text-decoration: none;
        border-top: 0px solid;
        opacity: 1.0;
        @media (min-width: 1460px) { 
            padding-left: 1.5rem !important;
        }
        .ws-ico {
            fill: $primary;
        }
        &:hover,
        &:active,
        &:focus {
            text-decoration: none;
            color: $black;
            opacity: 1.0;
            .ws-ico {
                fill: $black;
            }
        }
    }
        .nav-link.ws-cart { 
            padding-right: 0px;
            .badge {
                font-size: $font-size-base * 0.59;
                position: relative;
                top: -12px;
                right: 14px;
                width: 22px;
                height: 22px;
                border-radius: 50% !important;
                text-align: center !important;
                padding-left: 0 !important;
                padding-right: 0 !important;
                
            }
            .ws-price {
                margin-left: -10px;
            }
        }

        .nav-link.ws-login { 
           padding-right: 5px;
            .ws-name {
                
                margin-left: 13px;
                
            }
        }
    }
}


.ws-category-product-nav {
    padding: 0;
    nav.nav.nav-pills {
        background-color: $white;
        line-height: 50px;
    
        .nav-link {
            text-decoration: none;
            font-size: 1.05rem;
            color: $primary;
            fill: $white;
            &.active {
                background-color: transparent;
                color: $black;
                svg {
                    fill: $green;
                }
            }
            svg {
                margin-right: 5px;
                margin-top: -2px;
                fill: $gray-light;
                
                &.ws-ico-envelope {
                
                }
            }
            &:hover,
            &:active,
            &:focus {
                
                text-decoration: none;
                color: $black;
                svg {
                fill: $green;
            }
        }
        }
    }
}
